import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import MUIDialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@icon/Close';
import DialogMessage from './DialogMessage';
import { IDialogProps } from './types';

const Transition = forwardRef<
  unknown,
  TransitionProps & {
    children?: React.ReactElement;
  }
>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(MUIDialog)<{
  $center: boolean;
  $backgroundColor: string;
  $borderRadius: string;
  $mWebFullWidth: boolean;
}>`
  &.MuiDialog-root .MuiDialog-container .MuiPaper-root {
    ${({ $center }) => !$center && 'position: absolute;'}
    ${({ $center }) => !$center && 'bottom: 0px;'}
    background: ${({ $backgroundColor }) => $backgroundColor};
    ${({ $borderRadius }) =>
      $borderRadius ? `border-radius: ${$borderRadius};` : ''}
    @media only screen and (max-width: 540px) {
      width: ${({ $mWebFullWidth }) => ($mWebFullWidth ? '100%' : '95%')};
      margin: 16px auto;
    }
  }
`;

const CloseButton = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  cursor: pointer;
`;

const AboveDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const Dialog = ({
  children,
  type,
  dialogState,
  onClose,
  heading,
  message,
  messageWidth,
  messageColor,
  acceptLabel,
  acceptLabelVariant,
  acceptLabelTheme,
  rejectLabel,
  rejectLabelVariant,
  rejectLabelTheme,
  acceptOnClick,
  rejectOnClick,
  showButtons,
  shareContent,
  center,
  disableBackdropClick,
  className,
  borderRadius,
  backgroundColor,
  maxWidth,
  mWebFullWidth,
  aboveHeading,
  styledStringMsgWrapper
}: IDialogProps) => (
  <StyledDialog
    open={dialogState}
    TransitionComponent={Transition as React.ComponentType}
    onClose={onClose}
    $center={center}
    disableBackdropClick={disableBackdropClick}
    disableEscapeKeyDown={disableBackdropClick}
    className={className}
    $borderRadius={borderRadius}
    $backgroundColor={backgroundColor}
    maxWidth={maxWidth}
    $mWebFullWidth={mWebFullWidth}
  >
    {aboveHeading ? <AboveDiv>{aboveHeading}</AboveDiv> : null}
    {children || (
      <DialogMessage
        type={type}
        heading={heading}
        message={message}
        messageWidth={messageWidth}
        messageColor={messageColor}
        acceptLabel={acceptLabel}
        acceptLabelVariant={acceptLabelVariant}
        acceptLabelTheme={acceptLabelTheme}
        rejectLabel={rejectLabel}
        rejectLabelVariant={rejectLabelVariant}
        rejectLabelTheme={rejectLabelTheme}
        acceptOnClick={acceptOnClick}
        rejectOnClick={rejectOnClick}
        showButtons={showButtons}
        styledStringMsgWrapper={styledStringMsgWrapper}
      >
        {shareContent}
      </DialogMessage>
    )}
  </StyledDialog>
);

Dialog.propTypes = {
  type: PropTypes.oneOf(['default', 'destructive', 'informative']),
  dialogState: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  heading: PropTypes.string,
  message: PropTypes.string,
  messageWidth: PropTypes.string,
  messageColor: PropTypes.string,
  acceptLabel: PropTypes.string,
  acceptLabelVariant: PropTypes.oneOf(['disabled', 'hollow', 'filled']),
  acceptLabelTheme: PropTypes.oneOf(['red', 'green', 'black', 'blue']),
  rejectLabel: PropTypes.string,
  rejectLabelVariant: PropTypes.string,
  rejectLabelTheme: PropTypes.string,
  acceptOnClick: PropTypes.func,
  rejectOnClick: PropTypes.func,
  showButtons: PropTypes.bool,
  shareContent: PropTypes.node,
  children: PropTypes.node,
  center: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  className: PropTypes.string,
  borderRadius: PropTypes.string,
  backgroundColor: PropTypes.string,
  maxWidth: PropTypes.oneOf([false, 'xs', 'sm', 'md', 'lg', 'xl']),
  mWebFullWidth: PropTypes.bool,
  aboveHeading: PropTypes.node,
  styledStringMsgWrapper: PropTypes.string
};

Dialog.defaultProps = {
  type: 'destructive',
  showButtons: true,
  heading: '',
  children: null,
  message: null,
  messageWidth: '100%',
  messageColor: 'var(--color-text-primary)',
  acceptLabel: null,
  acceptLabelVariant: 'hollow',
  acceptLabelTheme: 'red',
  rejectLabel: null,
  rejectLabelVariant: 'hollow',
  rejectLabelTheme: 'black',
  shareContent: null,
  onClose: () => {},
  acceptOnClick: () => {},
  rejectOnClick: () => {},
  center: false,
  disableBackdropClick: false,
  className: '',
  borderRadius: '',
  backgroundColor: 'var(--color-base-1)',
  maxWidth: false,
  mWebFullWidth: false,
  aboveHeading: null,
  styledStringMsgWrapper: ''
};

export default Dialog;
